/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import './Footer.scss';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import TelegramIcon from '@mui/icons-material/Telegram';
import MapIcon from '@mui/icons-material/Map';
import EmailIcon from '@mui/icons-material/Email';
import { Link, useNavigation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { gql, useMutation, useQuery } from '@apollo/client';
import { URLConfig } from '../../utils/config';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import {
  formatNumberInBangla,
  getAmountWithCurrency,
} from '../../utils/functions';

const GET_TOTAL_SPENT = gql`
  query GetTotalSpentOnThePlatform {
    getTotalSpentOnThePlatform {
      _id
      num_payments
      total_spent
    }
  }
`;

const GET_STORY_COUNT = gql`
  query GetStoryCount {
    getStoryCount
  }
`;

const GET_USER_COUNT = gql`
  query GetUserCount {
    getUserCount
  }
`;

const Footer: React.FC<{}> = () => {
  const {
    data: total_spent_data,
    error: total_spent_dataError,
    loading: total_spent_dataLoading,
    refetch: total_spent_dataRefectch,
  } = useQuery(GET_TOTAL_SPENT);

  const {
    data: story_count_data,
    error: story_count_dataError,
    loading: story_count_dataLoading,
    refetch: story_count_dataRefectch,
  } = useQuery(GET_STORY_COUNT);

  const {
    data: user_count_data,
    error: user_count_dataError,
    loading: user_count_dataLoading,
    refetch: user_count_dataRefectch,
  } = useQuery(GET_USER_COUNT);

  const total_spent = total_spent_data?.getTotalSpentOnThePlatform;
  const total_story = story_count_data?.getStoryCount;
  const total_user = user_count_data?.getUserCount;

  const navigate = useNavigate();

  return (
    <footer className='footer_wrapper'>
      <div className='footer_content'>
        <div className='logo_content'>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              minWidth: 50,
            }}>
            <img
              className='logo_desk'
              style={{
                width: 85,
                cursor: 'pointer',
              }}
              src='/images/logo_white.png'
              alt='Logo'
            />
            <img
              className='logo_mob'
              style={{
                width: 50,
                cursor: 'pointer',
              }}
              src='/images/logo_white.png'
              alt='Logo'
            />
          </div>

          <div className='privacy_terms_link'>
            <Link to={'/privacy-policy'} style={{ marginRight: 10 }}>
              প্রাইভেসি পলিসি
            </Link>

            <Link to={'/terms-conditions'} style={{}}>
              টার্মস এবং কন্ডিশন
            </Link>
          </div>
        </div>
        <div className='actions_content'>
          <div className='action_links'>
            <div className='action_links_address'>
              <h3>কম্পানি</h3>
              <div className='action_links_address_icons'>
                <MapIcon style={{ color: '#fff', marginRight: 10 }} />
                <p>লন্ডন, যুক্তরাজ্য</p>
              </div>

              <div className='action_links_address_icons'>
                <WhatsAppIcon style={{ color: '#fff', marginRight: 10 }} />
                <p>+44 7915610027</p>
              </div>

              <div className='action_links_address_icons'>
                <EmailIcon style={{ color: '#fff', marginRight: 10 }} />
                <p>earnbymobile.uk@gmail.com</p>
              </div>
            </div>
            <div className='action_links_pages'>
              <h3>পেজগুলি</h3>
              <Link to={'/'} style={{}}>
                হোম
              </Link>
              <Link to={'/how-to-earn'} style={{}}>
                কিভাবে উপার্জন করবেন?
              </Link>
              <Link to={'/reviews'} style={{}}>
                রিভিউস
              </Link>
              <Link to={'/admin-support'} style={{}}>
                এডমিন সাপোর্ট
              </Link>
              <Link to={'/about-us'} style={{}}>
                আমাদের সম্পর্কে জানুন
              </Link>
            </div>
          </div>
          <div className='action_stats'>
            <div className='action_stats_numbers'>
              <div className='action_stats_numbers_item'>
                <h3>মোট টাকা প্রদান</h3>
                <p>
                  {total_spent?.total_spent
                    ? getAmountWithCurrency(total_spent.total_spent + 1230662)
                    : getAmountWithCurrency(0)}
                </p>
              </div>

              <div className='action_stats_numbers_item'>
                <h3>গল্প এপ্রোভ হয়েছে</h3>
                <p>
                  {total_story
                    ? formatNumberInBangla(total_story + 7300)
                    : formatNumberInBangla(0)}
                </p>
              </div>

              <div className='action_stats_numbers_item'>
                <h3>বর্তমান ব্যাবহারকারী আছে</h3>
                <p>
                  {total_user
                    ? formatNumberInBangla(total_user + 400)
                    : formatNumberInBangla(0)}
                </p>
              </div>
            </div>
            <div className='action_stats_newsletter'>
              <h3>নিউজলেটার</h3>
              <div className='action_stats_newsletter_input'>
                <input type='email' placeholder='আপনার ইমেইল দিন' required />
                <input
                  type='submit'
                  value='সাবস্ক্রাইব'
                  onClick={(e) => {
                    //@ts-ignore
                    e.target.value = 'অপেক্ষা করুন...';
                    setTimeout(() => {
                      //@ts-ignore
                      e.target.value = 'সাবস্ক্রাইব';
                      alert('Email recorded!');
                    }, 500);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='copyright_content'>
          <p>
            {moment(new Date()).format('YYYY')} &copy; Earn By Mobile (EBM) |
            All Right Reserved
          </p>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: 150,
            }}>
            <FacebookIcon
              onClick={() => {
                window.location.href = URLConfig.facebookURL;
              }}
              style={{ color: 'white', cursor: 'pointer' }}
            />
            <TwitterIcon
              onClick={() => {
                window.location.href = URLConfig.twitterURL;
              }}
              style={{ color: 'white', cursor: 'pointer' }}
            />
            <InstagramIcon
              onClick={() => {
                window.location.href = URLConfig.instagramURL;
              }}
              style={{ color: 'white', cursor: 'pointer' }}
            />
            <TelegramIcon
              onClick={() => {
                window.location.href = URLConfig.telegramURL;
              }}
              style={{ color: 'white', cursor: 'pointer' }}
            />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
