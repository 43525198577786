import React, { useEffect, useState } from 'react';
import './HowToEarnPage.scss';
import { webUrl } from '../../utils/config';
import { useLocation, useNavigate } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { authenticate } from '../../store/reducer/auth';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Colors from '../../constants/Colors';
import ReviewListItem from '../../components/ListItem/ReviewListItem';
import { URLConfig } from '../../utils/config';

const GET_ALL_TESTIMONIALS = gql`
  query GetAllTestimonialsByPage($input: GetTestimonialsByPageInput!) {
    getAllTestimonialsByPage(input: $input) {
      _id
      message
      isVisible
      rating
      createdAt
      added_by {
        name
        total_earnings
      }
    }
  }
`;

const GET_TARGET_DETAILS = gql`
  query GetTargetDetailsById($input: MongoIdInput!) {
    getTargetDetailsById(input: $input) {
      _id
      message
      timeSpent
      referred_by {
        _id
      }
      target_name
      target_phone
    }
  }
`;

const UPDATE_TARGET = gql`
  mutation UpdateAndSendContentToTarget($input: UpdateTargetInput!) {
    updateAndSendContentToTarget(input: $input) {
      _id
      target_name
      target_phone
    }
  }
`;

const HowToEarnPage: React.FC<{}> = (props) => {
  const [updateTarget, updateTargetData] = useMutation(UPDATE_TARGET);
  const [timeSpent, setTimeSpent] = useState(0);
  const [page_number, setPage_number] = useState(1);
  const navigate = useNavigate();

  const { search } = useLocation();
  const [targetID, setTargetID] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    const tID = search.split('=')[1];
    setTargetID(tID);
  }, [search.split('=')[1]]);

  const {
    data: testimonials_data,
    error: testimonials_dataError,
    loading: testimonials_dataLoading,
    refetch: testimonials_dataRefectch,
  } = useQuery(GET_ALL_TESTIMONIALS, {
    variables: {
      input: {
        page: page_number,
        item_per_page: 3,
      },
    },
  });

  const {
    data: target_data,
    error: target_dataError,
    loading: target_dataLoading,
    refetch: target_dataRefectch,
  } = useQuery(GET_TARGET_DETAILS, {
    variables: {
      input: {
        _id: search.split('=')[1] || targetID || '65c9411ae64e6232bbe4d140',
      },
    },
    fetchPolicy: 'network-only',
  });

  const target = target_data?.getTargetDetailsById;
  const reviews = testimonials_data?.getAllTestimonialsByPage || [];

  useEffect(() => {
    if (target) {
      dispatch(
        authenticate({
          isAuthenticated: true,
          target: {
            _id: target._id,
            target_name: target.target_name,
            target_phone: target.target_phone,
            referred_by: target.referred_by?._id,
          },
        })
      );
    }
  }, [target?._id]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeSpent((prev) => prev + 5);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const updateData = async () => {
      try {
        // Check if target is valid
        if (target) {
          // Update target object in the database
          await updateTarget({
            variables: {
              input: {
                _id: target._id,
                targetVisited: true,
                timeSpent: (target.timeSpent || 0) + 5,
              },
            },
          });

          // Refetch data after updating target
          await target_dataRefectch();
        }
      } catch (err) {
        console.error(err);
      }
    };

    updateData();
  }, [timeSpent]);

  return (
    <>
      <div className='how_to_earn_img'>
        <div className='how_to_earn_img_left'>
          <h2>আপনি আর মাত্র এক ধাপ পিছিয়ে</h2>

          <br />

          <p>
            Earn By Mobile (EBM) হলো এমন একটি প্লাটফর্ম যেখানে মেয়েরা ঘরে বসে
            মোবাইল এপের মাধ্যমে গল্প লিখে/গল্প বলে, কয়েন মাইনিং করে, গল্প পড়ে,
            রোবোট ট্রেইনিং করে, প্রশ্ন উত্তর দিয়ে, ভিডিও দেখে ও গেম খেলেই টাকা
            উপার্জন করতে পারে। এছাড়া টিমে জয়েন করে টিম লিডার হয়ে কাজ করলেও প্রতি
            মাসে ইন্টারনেট প্যাকেজ ও ইন্সেন্টিভ বোনাস পাওয়ার সুযোগ রয়েছে, এছাড়া
            বিভিন্ন ফেস্টিভাল বোনাস যেমন ঈদ/পুজার বোনাস তো আছেই। ইবিএম প্লাটফর্ম
            টি সম্পূর্ন যুক্তরাজ্য ভিত্তিক একটি প্রতিষ্ঠান, এবং সম্পূর্নরুপে
            যুক্তরাজ্য থেকে পরিচালনা করা হয়। বর্তমান বিশ্বে হাজার হাজার ওয়েবসাইট
            মোবাইল এপ আছে যেখানে টাকা ইনকামের কথা বলা হয় কিন্তু দেখা যায় কাজ
            করার পর টাকা পাওয়া যায়না, বা বের করা যায়না। কিন্তু এই প্লাটফর্ম
            সম্পূর্ন নিরাপদ একটি জায়গা। এটি কেনো শুধু মেয়েদের জন্য, কেনো
            যুক্তরাজ্য থেকে পরিচালনা করা হয়, কেনো এই দেশেই পরিচালনা করা হচ্ছে,
            কারা পরিচালনা করছে এর সব কিছুই এই পেজে বিস্তারিত আলোচনা করা হবে।
            আপনার সময় অনেক মূল্যবান তবুও যদি একটু সময় ব্যয় করে পড়ে দেখেন তাহলে
            সম্পূর্ন ব্যাপারে বুঝতে পারবেন।
          </p>

          <br />

          <p>
            আমাদের উদ্ধেশ্য হলো কয়েন মাইনিং করা ও গল্প সংগ্রহ করে সেগুলো কে
            আর্টিফিশিয়াল ইন্টেলিজেন্স এ প্রবেশ করিয়ে সেটিকে আরো বেশি হিউম্যানাইজ
            করার চেষ্টা, যুক্তরাজ্যের অনেক বড়ো একটি রিসার্স প্রতিষ্ঠান, এবং একটি
            রোবোটিক কম্পানির যৌথ প্রচেষ্টায় চ্যাটজিপিটির মত একটি আর্টিফিশিয়াল
            ইন্টেলিজেন্স মডেল তৈরি করার উদ্দেশ্যে আমরা মানুষের জীবনের সত্যি ঘটনা
            গুলো কে সংগ্রহ করছি। আমরা বিশ্বের বিভিন্ন অঞ্চলের বিভিন্ন দেশ থেকে
            এই তথ্য সংগ্রহ করেছি, এবং করছি। আমরা সম্পূর্ন নিরাপত্তার সাথে আপনার
            গল্প গুলো কে নিচ্ছি, এবং আপনাকে উপার্জনে সহায়তা করছি।
          </p>
        </div>

        <div className='how_to_earn_img_right'>
          <img
            style={{
              height: 220,
            }}
            src='/images/earn_taka.png'
            alt='Bus'
          />
        </div>
      </div>

      <br />

      <h3>এটি কিভাবে কাজ করে? </h3>
      <p>
        এই প্লাটফর্ম সম্পূর্ন এন্ড্রয়েড এপ এর উপর ভিত্তি করে চলে। এবং আপনাকে
        এপটি ডাউনলোড করে সেখানে কাজ শিখুন মেনু থেকে কাজ গুলো শিখে নিয়ে এর পর কাজ
        করতে হবে।
      </p>

      <ul>
        <br />
        <li>আপনাকে প্রথমে এই পেজ ভালো করে পড়ে বুঝে নিতে হবে।</li>
        <li>এর পর এই পেজের শেষে দেওয়া লিংক থেকে মোবাইল এপ টি ডাউনলোড করবেন।</li>
        <li>
          এই এপটি গুগল প্লেস্টোরে পাবেন না, কারন এধরনের কাজ গুগল এলাউ করেনা, তাই
          এটা আপনার এবং প্লাটফর্ম পরিচালনা কমিটির সবার জন্য সুবিধা যে আপনি গুগল
          ড্রাইভ বা পেজের নিচে দেওয়া লিংক থেকে এই এপটি ডাউনলোড করে আপনার ফোনে
          ইন্সটল করুন। লিংক এ ক্লিক দিলেই ডাউনলোড শুরু হবে। কিভাবে ইন্সটল করবেন
          তার গাউড নিচে দেওয়া হয়েছে।
        </li>
        <li>
          এর পর মোবাইল এপ ওপেন করে কাজ শিখুন মেনু থেকে কাজ শিখে তার পর কাজ করুন
          এবং প্রফাইল সম্পূর্ন করে টাকা নিন। আপনাকে অবশ্যই কাজ শিখুন পেজ থেকে
          সম্পূর্ন কাজ শিখে নিতে হবে, আপনি এখানে থাকা অনেক ধরনের কাজের মধ্যে
          যেকোনো ধরনের কাজ অথবা কয়েকটি কাজ করতে পারেন। একটু ভালো করে পড়ে
          সিদ্ধান্ত নিলে আসা করি বুঝতে পারবেন।
        </li>
      </ul>

      <br />

      <h3>আমরা আপনার নাম্বার/ইমেইল কিভাবে পেয়েছি?</h3>
      <p>
        যদি আপনি মেসেজ পেয়ে থাকেন, ইমেইল পেয়ে থাকেন অথবা ওয়াটসএপের মাধ্যমে
        আমাদের খোজ পেয়ে থাকেন তাহলে এই প্যারার লেখাটুকু আপনার জন্য। আপনারা
        হামেশাই বিভিন্ন প্রয়োজনে বিভিন্ন প্রতিষ্ঠান এ আপনাদের নাম্বার গুলো দিয়ে
        থাকেন, অথবা কোনো জাইগাতে চাকরির জন্য আবেদনে দিয়ে থাকেন। প্রতিটা দেশে
        এরকম অনেক প্রতিষ্ঠান আছে যাদের কাছে এই ধরনের সোর্চ থেকে অনেক
        নাম্বার/ইমেইল এক জায়গায় করে রাখে এবং পরে সেটি আমাদের মত প্রতিষ্ঠান কে
        দেয়, এই প্রতিষ্ঠান গুলো যারা নাম্বার গুলো বিক্রি করে অথবা যারা কেনে
        তাদের কোনো এসে জায় না আপনি কে আপনি কোথায় থাকেন কি করেন। এদের শুধু
        নাম্বার/ইমেইল ই দরকার হয় এর পর এই নাম্বারে বিভিন্ন মেসেজ পাঠানো হয়, যেমন
        আমরা পাঠিয়েছি। আমরা জানি না আপনি কে, কোথায় থাকেন, আমাদের একমাত্র প্রয়োজন
        যদি আমাদের প্লাটফর্ম আপনার ভালো লেগে থাকে তাহলে আপনি এটা ব্যাবহার করবেন।
        আমরা আপনার নম্বর টি এভাবে পেয়েছি এবং এটা মোটেই চিন্তার কিছু নয়, কারন হয়ত
        আপনি নিজেই দেখেছেন আপনি হয়ত অনেক অফিশিয়াল মেসেজ, বিজ্ঞাপনের মেসেজ পেয়ে
        থাকেন, এটা ঠিক এভাবেই।
      </p>

      <br />

      <h3>কিভাবে টাকা উপার্জন হয়?</h3>
      <p>
        এই প্লাটফর্মে প্রায় ১০ রকম পদ্ধতিতে টাকা উপার্জন হয়, আপনার যেভাবে ভালো
        লাগে আপনি সেভাবেই টাকা উপার্জন করতে পারেন। বাকি যা কিছু মোবাইল এপের
        মধ্যে আছে সেগুলা তখন না দেখলেও চলবে। আপনি অবশ্যই নিশ্চিত থাকুন আমরা জানি
        না আপনি কে, এবং আপনার কোনো গল্পই আমরা কোথাও প্রকাশ করিনা, এটি একটি টিমের
        মাধ্যমে যাচাই হয়ে সরাসরি ইন্টেলিজেন্স মডেলে ট্রান্সফার করা হয়। কাজেই
        আপনার নিরাপত্তা নিয়ে কোনো প্রশ্ন নেই।
      </p>

      <ul>
        <br />
        <li>
          গেম খেলার মাধ্যমে, আপনি কেবল এপের মধ্যে থাকা গেম খেলেই টাকা উপার্জন
          করতে পারেন, শুধু গেম খেলতে চাইলে গেম খেলা শিখে নিবেন এবং সেই গেম খেলেই
          পয়েন্টস অর্জন করে সেই পয়েন্টস দিয়ে টাকা উপার্জন করে নিতে পারবেন।
        </li>

        <li>
          মাইনিং করার মাধ্যমে, আপনি কেবল এপের মধ্যে থাকা কয়েন মাইনিং অপশন চালু
          রেখেই টাকা উপার্জন করতে পারেন। মাইনিং করতে নেট না হলেও চলবে। আপনি
          অফলাইনেও কয়েন মাইনিং করতে পারবেন। কিন্তু কয়েন কত হয়েছে বা আপডেট পেতে
          আপনাকে ইন্টারনেট চালু করে দেখতে হবে।
        </li>

        <li>
          গল্প লেখার মাধ্যমে/গল্প বলার মাধ্যমে, আপনার জীবনের বিশেষ বিশেষ কিছু
          গল্প যেঁটা এপের মধ্যে শিখে নিতে পারবেন কিভাবে লিখতে হয়, বিভিন্ন
          ক্যাটাগরিতে বিভিন্ন রকম গল্প আছে, আপনার জীবনের সেই গল্প গুলো লিখে আপনি
          আমাদের কে দিতে পারেন এবং উপার্জন করতে পারেন। এই গল্প আপনার জীবনের হতে
          হবে, সত্যি গল্প হতে হবে অথবা আপনার জীবনের গল্প গুলো একটু সাজিয়ে,
          সুন্দর ভাবে, (যেঁটা আপনাকে এপের মধ্যে শিখিয়ে দেওয়া হবে) লিখে দিলে
          আমাদের টিম সেটি যাচাই করে যদি এপ্রোভ করে দেয় তাহলে সেই ক্যাটাগরির গল্প
          লেখার জন্য নির্ধারিত পয়েন্টস পেয়ে সেই পয়েন্টস এর বিনিময়ে আপনি টাকা
          পাবেন।
        </li>

        <li>
          রোবোট ট্রেইনিং করার মাধ্যমে, আপনি চাইলে আমাদের প্লাটফর্মে রোবোট কে
          ট্রেইনিং করাতে পারেন। এখানে আপনি ভয়েস মেসেজ অথবা মেসেজ লিখে চ্যাট করতে
          পারবেন। নির্দিষ্ট সময়ের জন্য আপনাকে পয়েন্টস দেওয়া হবে। এখানে আপনার
          ভয়েস সম্পূর্ন পরিবর্তন হয়ে এর পর আমাদের রোবোট সার্ভারে যাবে।
        </li>

        <li>
          কন্টেন্ট আপলোড এর মাধ্যমে, আপনি চাইলে আমাদের এ আই সাজেস্টেড ছবি বা
          ভিডিও আপলোড করে অনেক কয়েন উপার্জন করতে পারে। আগে সব ভালো করে পড়ে
          নিবেন।
        </li>

        <li>
          প্রশ্ন উত্তর এর মাধ্যমে, আপনি নির্দির্ষ্ট কিছু টাকা উপার্জন করতে
          পারলেই আপনার জন্য একটি নতুন টাকা উপার্জন এর উপায় বের হবে। সেটি হলো
          প্রশ্ন উত্তর এর মাধ্যমে। আপনার গল্প এনালাইসিস করে অথবা গেম খেলার
          একটিভিটি দেখে আমাদের আর্টিফিশিয়াল মডেল সয়ংক্রিয় ভাবে কিছু প্রশ্ন তৈরি
          করবে। এবং আপনাকে প্রতিনিয়ত পাঠাবে কিছু সময় পর পর অথবা কয়েকদিন পর পর।
          আপনাকে সেই প্রশ্ন গুলোর উত্তর প্রদান করতে হবে। আর্টিফিশিয়াল মডেল
          সেগুলো যাচাই করে আপনাকে পয়েন্টস প্রদান করবে।
        </li>

        <li>
          [এটি করার আগে আপনি ভালো করে প্লাটফর্ম সম্পর্কে জানুন এর পর করুন।]
          আপনার পরিচিত কারো নাম্বার প্রদান করে, আপনি আমাদের প্লাটফর্মে উপযুক্ত
          গল্প লিখতে পারবে এরকম কোনো পরিচিত জনের নাম্বার আমাদের কে রিফার করলে
          আমরা সেই নাম্বারে সম্পূর্ন গোপোনীয়তার সাথে এই প্লাটফর্ম এ আসার মেসেজ
          করবো, এবং সে জানতে পারবেনা আপনি তাকে রিফার করেছেন, অথবা আপনিও জানতে
          পারবেন না সে মেসেজ পড়েছে কিনা, প্লাটফর্মে এসেছে কিনা, শুধুমাত্র যদি সে
          প্লাটফর্মে আসে আপনার রিফার কোডের মাধ্যমে তাহলে আপনার এবং তার একাউন্টে
          কিছু পয়েন্টস যুক্ত হবে, এবং সে যে গল্প লিখে উপার্জন করবে তার কিছু
          পারসেন্টেজ আপনিও পাবেন। দুই পক্ষের কেউ ই জানতে পারবেনা কে কি করেছে কি
          করেনি। কাজেই এই পদ্ধতিও সম্পূর্ন নিরাপদ, আপনাকে অবশ্যই তার নাম্বার
          সঠিক লিখতে হবে, আর নাম ফেক দিলেও কোনো সমস্যা হবেনা। আমরা সব সময় এই
          সুযোগ দেইনা, অথবা নাম্বার শেয়ার করলে অনেক দিন পর মেসেজ পাঠায় যেনো
          বুঝতে না পারে আপনি কে বা সে কে। আপনি নিজে থেকে কাউকে এই প্লাটফর্ম
          সম্পর্কে বলবেন না। কাউকেই না।
        </li>

        <li>
          গল্প পড়ার মাধ্যমে, আমাদের প্লাটফর্ম সম্পর্কে জেনে ভালো ভাবে কাজ করতে
          হলে আপনাকে গল্প লেখা শিখতে হবে। আর গল্প লেখা শিখতে গেলে উদাহরন হিসেবে
          দেওয়া গল্প পড়তে হবে। আমাদের এখানে সব ক্যাটাগরিতে গল্প লেখা আছে।
          প্রতিটি গল্প "গল্প পড়ুন" মেনুতে পাবেন। সেখানে আপনাকে যে ক্যাটাগরি
          পছন্দ সেই ক্যাটাগরি সিলেক্ট করে আপনি গল্প পড়তে পারেন। গল্প পড়লেই
          প্রতিটি গল্প পড়ার জন্য আপনার একাউন্টে পয়েন্টস জমা হবে।
        </li>

        <li>
          ক্যাটালগ দেখার মাধ্যমে, আমাদের প্লাটফর্ম সম্পর্কে জেনে ভালো ভাবে কাজ
          করতে হলে আপনাকে গল্প লেখা শিখতে হবে। আর গল্প লেখা শিখতে গেলে উদাহরন
          হিসেবে দেওয়া ক্যাটালগের ছবি ও ভিডিও দেখতে হবে। আপনি "ভিডিও ও ছবি" মেনু
          থেকে নির্দেশনা অনুশরন করে ক্যাটালগ দেখেও উপার্জন করতে পারেন।
        </li>

        <li>
          ক্যাটালগ দেখে কন্টেন্ট এর অনুরোধ এর মাধ্যমে, ভিভিও ও ছবি মেনু তে একটি
          অপশন আছে কন্টেন্ট রিকুয়েস্ট, আপনি আপনার প্রয়োজনীয় কন্টেন্ট এর অনুরোধ
          করেও কিছু পয়েন্টস উপার্জন করতে পারবেন।
        </li>
      </ul>

      <br />

      <h3>কিভাবে শুরু করবেন?</h3>

      <p>
        আপনাকে কাজ করতে হলে অবশ্যই সব কিছু আগে ভালো ভাবে পড়ে নিতে হবে, আমি আবারো
        বলছি এই প্লাটফর্মে কাজ করতে হলে প্রতিটি পেজের লেখা গুলো খুব মনোযোগ দিয়ে
        পড়তে হবে, শুরু থেকে শেষ পর্যন্ত। আপনার এখন সময় না থাকলে পরে আসুন, কিন্তু
        এপ ডাউনলোড করার আগে আপনাকে অবশ্যই এই পেজ ভালো করে পড়ে নিতে হবে। এবং এপ
        ডাউনলোড করার আগে সর্ব প্রথম সাইন আপ করে কাজ শিখুন মেনু থেকে অবশ্যই কাজ
        শিখে নিতে হবে।
      </p>

      <ul>
        <br />
        <li>
          প্রথমেই বলে নেই, এই এপের মাধ্যমে বা ওয়েবসাইটের মাধ্যমে আপনার কোনো
          ক্ষতি হবেনা, অথবা আপনার কোনো ব্যক্তিগত তথ্য আমরা দেখতেও পারবোনা। এমনি
          আমরা জানতেও পারবোনা আপনি কে। আমাদের আমাদের সাইটের নিরাপত্তা নিয়ে আমরা
          অনেক গবেষনা করেছি, এবং আপনাদের নিরাপত্তার জন্য অনেক পদ্ধতি অবলম্বন
          করেছি। এবং এখানে কাজ করতে হলে আপনার মোবাইলে নেট থাকা লাগবে, WiFi থাকলে
          খুব ভালো।
        </li>
        <li>
          এই{' '}
          <a href={`${URLConfig.appDownloadUrl}`} target='_blank'>
            লিঙ্ক
          </a>{' '}
          থেকে এপ টি ডাউনলোড এ দিয়ে রাখুন। ডাউনলোড হতে থাক আর আপনি পড়তে থাকেন।
          পড়া শেষ হলেই ইন্সটল করবেন এর আগে না। ভিপিএন চালু করে সব কাজ করলে আপনি
          সিকিউরড থাকবেন এবং ইন্টারনেটে স্পীড ভালো পাবেন যদি এপ অনেক স্লো
          ডাউনলোড হয় ভিপিএন চালু করে নিবেন।
        </li>

        <li>
          এই প্লাটফর্ম খুব ভালো ভাবে চালাতে, মাইনিং চালাতে ভালো ভাবে গেম খেলতে
          কন্টেন্ট দেখতে গুগল প্লে থেকে যেকোনো একটি ভিপিএন ইন্সটল করে নিবেন।
          এখানে একটি সিকিউরড এবং নাম করা ভিপিএন এর লিঙ্ক দেওয়া আছে এটিও ইন্সটল
          করে নিতে পারেন{' '}
          <a
            href={`https://play.google.com/store/apps/details?id=com.cloudflare.onedotonedotonedotone`}
            target='_blank'>
            ভিপিএন লিঙ্ক এখানে
          </a>{' '}
          ভিপিএন চালু রাখলে আপনি আরো বেশি সিকিউরড থাকবেন কেননা ভিপিএন চালু রাখা
          মানে আপনাকে ইন্টারনেটে কেউ ধরতে পারবেনা আপনে কে কোথা থেকে।
        </li>
        <li>
          এপ টি ডাউনলোড করার পর আপনাকে ইন্সটল করতে হবে, যেহেতু আপনারা জানেন যে
          গুগল সব ধরনের দ্রুত ইঙ্কামের এপ গুলো কে রিমুভ করে দিয়েছে, এবং কিছু
          ধরনের এপ তারা রাখেনা তাই আমাদের এপটি প্লে স্টোরে নেই, এখন। বাইরে থেকে
          ইন্সটল করতে গেলে আপনার ফোনে তিন ধরনের সমস্যা হতে পারে, যদি আপনার কোনো
          সমস্যা না হয় তাহলে আপনাকে এই গুলোর কোনো পদ্ধতি দেখতে হবেনা, আর যদি
          কোনো টা হয় তাহলে দেখবেন । ১) আননোন সোর্চ থেকে ইন্সটল করার পারমিশন ২)
          গুগল প্লে প্রোটেকশন (যেহেতু গুগল প্লে তে এপ টি নেই।) ৩) এপ নট
          ইন্সটল্ড, এখানে তিন ধরনের সমস্যার সমাধান ই দেওয়া হয়েছে। আপনার মোবাইলে
          একটি সমস্যাও না হতে পারে এটা নির্ভর করে আপনি কেমন ফোন চালান। এর বাইরের
          কোনো সমস্যার যদি কোনো সমাধান না করতে পারেন তাহলে সরাসরি আপনি আমাদের
          সাপোর্ট টিম কে WhatsApp এর মাধ্যমে জানাতে পারেন। এই সাইটের একেবারে
          শেষে নম্বর দেওয়া আছে।
        </li>
        <br />
        <li>
          Install From Unknown Source: এই সমস্যা দেখালে আপনাকে এটা এলাউ করতে
          হবে, এটার মানে হলো মোবাইল আপনাকে জানতে চাই যে আপনি কোথা থেকে এপ ইন্সটল
          করছেন আপনি জানেন কিনা। এখানে আপনি আননোন সোর্চ অন করতে গেলে তিন নাম্বার
          ছবির মত ড্যাঞ্জার পেজ ও দেখতে পারেন এটি কোনো সমস্যা নয় যদি আপনি দেখতে
          পান টিক চিহ্ন দিয়ে ওকে প্রেস করবেন। এটা যেকোনো এপ প্লের বাইরে থেকে
          ইন্সটল করতে গেলে দেখায়।
          <br />
          <img style={{}} src='/images/unknownsource1.jpeg' alt='Unknown 1' />
          <img style={{}} src='/images/unknownsource2.jpg' alt='Unknown 2' />
          <img style={{}} src='/images/danger_image.jpg' alt='Danger' />
        </li>

        <br />
        <li>
          Blocked By Play Protect: এই সমস্যা দেখালে আপনাকে Install Anyway তে
          প্রেস করতে হবে। প্লে স্টোরে না থাকলে এরকম দেখাতে পারে মাঝে মাঝে, না
          দেখালে সমস্যা নেই।
          <img style={{}} src='/images/playprotect.png' alt='Bus' />
        </li>

        <br />
        <li>
          App Not Installed: এই সমস্যা দেখা দিলে আপনাকে পুনোরায় কয়েকবার চেষ্টা
          করতে হবে, যদি তাও না হয় তাহলে গুগল প্লেস্টোরে ঢুকবেন, ঢুকে একাউন্ট এর
          মধ্যে দিয়ে প্লে প্রোটেকশন অফ করে দিবেন।
          <img style={{}} src='/images/appnotinstalled.png' alt='Bus' />
          <br />
          <br />
          <p>
            অফ করার নিয়ম সিরিয়ালে দেওয়া আছে, প্রথমে প্লেস্টোরে ঢুকবেন, এর পর ডান
            পাশের উপরের কোনায় চাপ দিবেন, একাউন্টে ঢুকার জন্য
          </p>
          <img style={{}} src='/images/pps1.jpg' alt='Bus' />
          <br />
          <br />
          <p>সেখানে প্লে প্রোটেক্ট নামে একটি মেনু আছে সেখানে ঢুকবেন।</p>
          <img style={{}} src='/images/pps2.jpg' alt='Bus' />
          <br />
          <br />
          <p>এর পর ডান পাশের উপরের কোনায় সেটিং এ ঢুকবেন। </p>
          <img style={{}} src='/images/pps3.jpg' alt='Bus' />
          <br />
          <br />
          <p style={{}}>এবার এখানের সুইচ এরকম অফ করে দিবেন, যদি অন করা থাকে।</p>
          <img style={{}} src='/images/pps4.jpg' alt='Bus' />
        </li>

        <li>
          এপ টি ইন্সটল হয়ে গেলে আপনি সাইন আপ করবেন, সাইন আপ করার টাইমে আপনি এই
          কোড টি ব্যাবহার করলে ২০ টি পয়েন্টস পাবেন, প্রতিটি পয়েন্টস এর মূল্য এক
          টাকা করে মানে ২০ টাকা পাবেন। পয়েন্টস পেতে নিচের কুপন কোড টি কপি করে
          বসিয়ে দিন, কুপোন কোডের ঘরে। সবার কুপন কোড আলাদা। আপনি অন্য কারো কুপন
          কোড ব্যাবহার করতে পারবেন না। আপনি আপনাকে পাঠানো কুপোন কোডই ব্যাবহার
          করবেন এনং সেটি কাউকে শেয়ার করবেন না।
          <p style={{ marginBottom: 10 }}>
            আপনার কুপোন কোডঃ <strong>{targetID}</strong>
          </p>
          {target && (
            <CopyToClipboard
              text={targetID}
              onCopy={() => {
                alert('কুপোন কোড কপি হয়েছে');
              }}>
              <span
                style={{
                  color: Colors.primaryColorLight,
                  cursor: 'pointer',
                }}>
                কুপোন কোড কপি করতে এখানে ক্লিক করুন
              </span>
            </CopyToClipboard>
          )}
          <p style={{ marginTop: 10 }}>
            যদি কুপোন কোড না দেখতে পান তাহলে কুপোন অফার টি আপনার জন্য প্রযজ্য না
          </p>
        </li>

        <li>
          সাইন আপ করতে প্রথমে আপনার ছবি বা কারো ছবি বাদে যেকোনো একটু ফুল ফল বা
          যার ইচ্ছা তার ছবি যুক্ত করবেন। ছবি যুক্ত করতে পারমিশন চাইলে দিবেন। এর
          পর সকল ইনফরমেশন এর সাথে আপনার ইমেইল ও ফোন নাম্বার দিবেন, মনে রাখবেন
          আপনি আপনার অন্য তথ্য যেকোনো কিছু ফেক দিলেও নাম্বার এবং ইমেইল সঠিক
          দিবেন কারন এখানে আপনার ইমেল এ কোড যাবে, এবং আপনার নাম্বার দিয়ে টাকা
          বের করার সময় আমাদের ওয়াটসএপে একটি মেসেজ করতে হবে, যদি আমাদের সন্দেহ হয়
          যে আপনি কোনো চালাকি করে আমাদের প্লাটফর্ম কে ধোকা দিয়ে টাকা ইঙ্কাম করতে
          চান, তাহলে আমরা একাউন্ট বন্ধ করে দেই। সাইন আপের সময় নাম্বার লেখার ঘরে
          নাম্বার অটো লেখতে চাইলে কন্টাক পারমিশন চালু করে দিবেন, আবার ভেরিফিকেশন
          কোড অটোমেটিক চালু করার জন্য মেসেজ এর পারমিশন দিবেন, এছাড়া আপনার
          একাউন্ট ভেরিফাই এর জন্য, এবং আপনি বাংলাদেশ থেকেই একাউন্ট করছেন কিনা
          সেটা জানার জন্যো আরো কিছু পারমিশন চাইলে দিতে হবে। অথবা ইমেইলে আপনার
          কোড চলে যাবে সেখানে দেখতে পাবেন। আর আপনি একাউন্ট তৈরি করার পর প্রফাইলে
          ঢুকে বিকাশ নাম্বার যুক্ত করতে পারেন, যেখানে আমরা টাকা পাঠাবো, আপনি
          টাকা উইথড্র দেওয়ার ৭ দিনের মধ্যে আপনার বিকাশে টাকা ঢুকে যাবে, আমাদের
          লোকাল বিকাশ এজেন্ট বাঙ্গলাদেশের সকল জেলা তে আছে, এবং টাকা সরাসরি
          রেমিটেন্স এর মাধ্যমে বিকাশে, অথবা যেকোনো ব্যাংক চ্যানেলের মাধ্যমে
          বিকাশে ঢুকবে। আপনাকে টাকা দেওয়ার পর আমাদের সকল এজেন্ট আপনার নাম্বার
          এবং টাকা পাঠানোর মেসেজ ডিলেট করে দেয়, কাজেই আপনার পরিচয় কোথাও থেকে বের
          হওয়ার সুযোগ নেই, আর আপনিও এই মেসেজ গুলো ডিলেট করে দিবেন। যেকোন সমস্যায়
          আপনি আমাদের ওয়াটসএপে পাবেন আমাদের টিম আপনাকে সাহায্য করবে ২৪ ঘন্টা।
        </li>

        <li>
          আপনি চাইলে <a href={`${webUrl}/reviews`}>এখানে</a> ক্লিক করে এই
          প্লাটফর্মে অন্যদের দেওয়া রিভিউ গুলো দেখতে পারেন। অথবা এপের মধ্যে লাইভ
          টাকা প্রদানের তথ্য ও দেওয়া থাকে। কাজেই এটি সম্পূর্ন নিরাপদ।
        </li>

        <li>
          আপনাকে লগিন করার পর সর্বপ্রথম কাজ শিখুন বাটনে ক্লিক করে বিভিন্ন ধরনের
          কাজ শিখে নিতে হবে, কাজ শেখার কয়েকটি ধাপের মধেও উপার্জন এর সুযোগ গুলো
          নিতে পারেন। আপনি কাজ শিখুন বাটনে চাপ দিয়ে সমস্ত কিছু পড়ে নেওয়ার পর
          এবার আপনি "গল্প পড়ুন" মেনু থেকে উদাহরন হিসেবে দেওয়া গল্প পড়তে পারেন।
          এবং এর পর আরো বেশি পয়েন্টস পেতে আপনি "ভিডিও ও ছবি" মেনু থেকে ক্যাটালগ
          দেখতে পারেন। সব কিছু দেখা হলেই আপনি বুঝে যাবেন কিভাবে গল্প লিখতে হবে,
          আপনার জীবনের গল্প গুলোকে কিভাবে সাজাতে হবে সব বুঝে যাবেন। এছাড়া আপনার
          ফ্রি টাইমে মাইনিং চালু রাখলেও ইনকাম করতে পারবেন।
        </li>

        <li>
          এখানে ৮ ধরনের কাজের মধ্যে থেকে আপনি যেকোনো কাজ করতে পারেন, যেমন আপনার
          যদি শুধু গেম ভালো লাগে তাহলে গেম খেলবেন। মাঝে মাঝে গল্প পড়লেন,
          ক্যাটালগ দেখলেন মাঝে মাঝে ইচ্ছা হলে। এবং যদি নিজের জীবনের কিছু গল্প
          থাকে তাহলে শেয়ার করেও উপার্জন করলেন। সাথে সাথে প্রশ্ন উত্তর এর
          মাধ্যমেও উপার্জন করতে পারেন। এছাড়া আপনি কিছু না করেও মাইনিং চালু রেখেও
          ইনকাম করতে পারবেন।
        </li>

        <li>
          সব কাজ শিখে এই প্লাটফর্ম সম্পর্কে ভালো ভাবে জেনে যদি আপনি রিফার করে
          উপার্জন করা শিখতে চান তাহলে রিফার করুন মেনু তে ঢুকবেন। আপনি এই এপ
          সম্পর্কে কাউকে বলবেন না, এই এপ এ লকের ব্যবস্থা আছে, আপনি অবশ্যই এই এপ
          টি লক করে রেখে দিবেন যেনো অন্য কেউ এর মধ্যে ঢুকতে না পারে। অথবা চাইলে
          এপ লক ও লাগিয়ে রাখতে পারেন। এপে লক লাগাতে অথবা বন্ধ করতে "সিকুরিটি
          পিন" মেনুতে ঢুকুন। মনে রাখবেন এই এপ নিয়ে বাইরে কারো সাথে আলোচনা করবেন
          না, আমরা খুব সিকুরিটির মাধ্যমে অন্তন্ত গোপোনীয়তার সাথে এই প্লাটফর্ম
          পরিচালনা করি, এজন্য আপনি কাউকে রিফার করতে চাইলে আমাদের মাধ্যমে করে
          নিতে পারেন। কাউকে মুখে মুখে শেয়ার করলে আপনার ই ক্ষতি, আমাদের এই
          প্লাটফর্ম খুব নির্ধারিত সংখ্যক লোকজন দিয়েই পরিচালিত হয় এবং এর ব্যাবহার
          কারীরাও খুব সন্তুষ্ট। আপনি সেগুলো রিভিউ দেখে বুঝতে পারবেন।
        </li>

        <li>
          এই এপ চালাতে কোনো সমস্যা হলে আমাদের কে সরাসরি টেলিগ্রাম অথবা ওয়াটসএপ এ
          ২৪ ঘন্টা পাবেন। সব থেকে ভালো হয় এপ ইন্সটল এর পর কোনো কিছু Allow অথবা
          পারমিশন এর মত আসলে সেগুলা চালু করে সামনে এগিয়ে যাওয়া। এই এপ চলতে আপনার
          বেশ কিছু পারমিশন হয়ত নিবে, কারন এই এপ প্লে স্টোরে নেই। কেনো নেই সেটা
          হয়ত সমস্ত কাজ শিখে গেলে বুঝে যাবেন। এজন্য কোনো Allow চাইলে করবেন, যেমন
          ভাবে অন্য এপে করেন, যেমোন ফটো যুক্ত করতে ক্যামেরা, ছবি যুক্ত করতে
          মিডিয়া এরকম, যেগুলা ফেসবুক বা মেসেঞ্জারেও যেম্নে চাই শুরুতে। আমরা সব
          সমস্যা সমাধান মোটামুটি দেখিয়েছি এর পরেও সমস্যা হলে আমাদের কে জানাবেন।
        </li>
      </ul>

      <br />

      <p>
        আমাদের প্লাটফর্ম এর সাথে যুক্ত হউন এবং নিজের জীবন কে বদলে ফেলুন,
        নিশ্চিন্তে নিরাপদে ঘরে বসেই উপার্জন করুন।
      </p>

      <br />

      <p>
        আপনার কোনো ধরনের প্রশ্ন থাকলে নিচে দেওয়া ইমেইলে, অথবা ওয়াটসএপ নাম্বারে
        যোগাযোগ করুন, অথবা এপের মধ্যেই মেসেজের ব্যবস্থা আছে সেখানে আমাদের টিম
        থেকে সাহায্য নিতে পারেন। নিচের লিংক থেকে ডাউনলোড করুন এপ।
      </p>

      <br />

      <img
        className='play_icon'
        onClick={() => {
          window.open(URLConfig.appDownloadUrl, '_blank');
        }}
        style={{
          cursor: 'pointer',
        }}
        src='/images/googleplay.png'
        alt='Bus'
      />

      <br />
      <br />

      <br />
      <p>শুভকামনায়,</p>
      <p>Earn By Mobile (EBM) Team</p>

      <div className='home_testimonial_section'>
        <h2>আমাদের ব্যাবহার কারীদের রিভিউ</h2>
        {/* WILL CHAGE TO CUSTOMER REVIEW TO COMPANY */}
        <div className='home_testimonial_section_items'>
          {reviews &&
            reviews?.map((rv: any) => {
              return (
                <ReviewListItem
                  _id={rv._id}
                  key={rv._id}
                  createdAt={rv.createdAt}
                  rating={rv.rating}
                  message={rv.message}
                  review_by={{
                    name: rv.added_by.name,
                    total_earnings: rv.added_by.total_earnings,
                  }}
                />
              );
            })}
        </div>
        <div className='home_testimonial_section_action'>
          <input
            type='submit'
            value='আরো দেখুন'
            onClick={() => {
              navigate('/reviews');
            }}
          />
        </div>
      </div>
    </>
  );
};

export default HowToEarnPage;
