type URLCONFIG = {
  graphQLURL: string;
  appDownloadUrl: string;

  //Social Links
  facebookURL: string;
  twitterURL: string;
  instagramURL: string;
  telegramURL: string;
};

export const isLive = true;
const isIPhone = false;
const isMobileIP = false;

const localIPMobile1 = 'http://192.168.43.2';
const localIPMobile2 = 'http://172.20.0.3';
const localIPWiFi = 'http://192.168.0.105';

const localIPMobile = isIPhone ? localIPMobile2 : localIPMobile1;
const localIP = isMobileIP ? localIPMobile : localIPWiFi;

export const webUrl = isLive ? 'https://earnbymobile.site' : `${localIP}:3000`;

export const gBaseUrl = isLive
  ? 'https://api-ts.fully-booked.uk'
  : `${localIP}:8000`;

export const URLConfig: URLCONFIG = {
  graphQLURL: `${gBaseUrl}/graphql`,
  appDownloadUrl:
    'https://drive.google.com/file/d/1DURzNQ0y-flSGumeGMPGHStNa5g85Rgn/view?usp=sharing',

  // Social Links
  facebookURL: 'https://www.facebook.com/',
  twitterURL: 'https://twitter.com/',
  instagramURL: 'https://twitter.com/',
  telegramURL: 'https://t.me/earnbymobilesite',
};
