import React from 'react';
import './AboutUsPage.scss';

const AboutUsPageApp: React.FC<{}> = (props) => {
  return (
    <>
      <div className='about_us_img'>
        <div className='about_us_img_left'>
          <h2>আমাদের এপে আপনাকে স্বাগতম</h2>

          <br />

          <p>
            Earn By Mobile (EBM) হলো এমন একটি প্লাটফর্ম যেখানে মেয়েরা ঘরে বসে
            মোবাইল এপের মাধ্যমে গল্প লিখে/গল্প বলে, কয়েন মাইনিং করে, রোবোট
            ট্রেইনিং করিয়ে, প্রশ্ন উত্তর দিয়ে, গল্প পড়ে, ভিডিও দেখে ও গেম খেলেই
            টাকা উপার্জন করতে পারে। ইবিএম প্লাটফর্ম টি সম্পূর্ন যুক্তরাজ্য
            ভিত্তিক একটি প্রতিষ্ঠান, এবং সম্পূর্নরুপে যুক্তরাজ্য থেকে পরিচালনা
            করা হয়। বর্তমান বিশ্বে হাজার হাজার ওয়েবসাইট মোবাইল এপ আছে যেখানে
            টাকা ইনকামের কথা বলা হয় কিন্তু দেখা যায় কাজ করার পর টাকা পাওয়া
            যায়না, বা বের করা যায়না। কিন্তু এই প্লাটফর্ম সম্পূর্ন নিরাপদ একটি
            জায়গা। এটি কেনো শুধু মেয়েদের জন্য, কেনো যুক্তরাজ্য থেকে পরিচালনা করা
            হয়, কেনো এই দেশেই পরিচালনা করা হচ্ছে, কারা পরিচালনা করছে এর সব কিছুই
            এই পেজে বিস্তারিত আলোচনা করা হবে। আপনার সময় অনেক মূল্যবান তবুও যদি
            একটু সময় ব্যয় করে পড়ে দেখেন তাহলে সম্পূর্ন ব্যাপারে বুঝতে পারবেন।
          </p>

          <br />

          <p>
            আমাদের উদ্ধেশ্য হলো কয়েন মাইনিং করা ও গল্প সংগ্রহ করে সেগুলো কে
            আর্টিফিশিয়াল ইন্টেলিজেন্স এ প্রবেশ করিয়ে সেটিকে আরো বেশি হিউম্যানাইজ
            করার চেষ্টা, যুক্তরাজ্যের অনেক বড়ো একটি রিসার্স প্রতিষ্ঠান, এবং একটি
            রোবোটিক কম্পানির যৌথ প্রচেষ্টায় চ্যাটজিপিটির মত একটি আর্টিফিশিয়াল
            ইন্টেলিজেন্স মডেল তৈরি করার উদ্দেশ্যে আমরা মানুষের জীবনের সত্যি ঘটনা
            গুলো কে সংগ্রহ করছি। আমরা বিশ্বের বিভিন্ন অঞ্চলের বিভিন্ন দেশ থেকে
            এই তথ্য সংগ্রহ করেছি, এবং করছি। আমরা সম্পূর্ন নিরাপত্তার সাথে আপনার
            গল্প গুলো কে নিচ্ছি, এবং আপনাকে উপার্জনে সহায়তা করছি।
          </p>
        </div>

        <div className='about_us_img_right'>
          <img
            style={{
              height: 220,
            }}
            src='/images/earn_taka.png'
            alt='Bus'
          />
        </div>
      </div>

      <br />

      <h3>এটি কিভাবে কাজ করে? </h3>
      <p>
        এই প্লাটফর্ম সম্পূর্ন এন্ড্রয়েড এপ এর উপর ভিত্তি করে চলে। এবং আপনাকে
        এপটি ডাউনলোড করে সেখানে কাজ শিখুন মেনু থেকে কাজ গুলো শিখে নিয়ে এর পর কাজ
        করতে হবে।
      </p>

      <ul>
        <li>
          প্রথমে আপনি ওয়েবসাইটের "কিভাবে উপার্জন করবেন?" পেজ থেকে ভালো করে
          প্রসেস গুলো পড়ে নিবেন।
        </li>
        <li>এর পর হোম পেজে দেওয়া লিংক থেকে মোবাইল এপ টি ডাউনলোড করবেন।</li>
        <li>
          এই এপটি গুগল প্লেস্টোরে পাবেন না, কারন এধরনের কাজ গুগল এলাউ করেনা, তাই
          এটা আপনার এবং প্লাটফর্ম পরিচালনা কমিটির সবার জন্য সুবিধা যে আপনি গুগল
          ড্রাইভ বা হোম পেজে দেওয়া লিংক থেকে এই এপটি ডাউনলোড করে আপনার ফোনে
          ইন্সটল করুন, ইন্সটলের সময় ইরোর আসলে কিভাবে উপার্জন করবেন পেজ থেকে সব
          জেনে নিবেন কি করতে হবে।
        </li>
        <li>
          এর পর মোবাইল এপ ওপেন করে কাজ শিখুন মেনু থেকে কাজ শিখে তার পর কাজ করুন
          এবং প্রফাইল সম্পূর্ন করে টাকা নিন।
        </li>
      </ul>

      <br />

      <h3>কেনো আপনি এই প্লাটফর্মে কাজ করবেন</h3>

      <ul>
        <li>
          মেয়েদের জন্য সম্পূর্ন নিরাপদ একটি সাইট, যেখানে আপনি কি করছেন না করছেন
          অন্য কারো জানার বা দেখার কোনো সুযোগ নেই।
        </li>
        <li>
          আপনি কে সেটা আমরা মোটেই জানিনা, আমরা বিভিন্ন মাধ্যম থেকে নাম্বার
          ম্যানেজ করে মেসেজের মাধ্যমে মানুষ কে গল্প লেখায় আহবান করি।
        </li>
        <li>
          এই এপ টির কোনো তথ্য অন্য কোথাও প্রকাশ করা হয় না, এটা শুধুমাত্র এ আই
          মডেলের জন্য। রোবোটিক হিউম্যানাইজেশন ট্রেইনিং এর জন্য ব্যাবহৃত হয়।
        </li>
        <li>
          আপনার পরিচিত কেউ জানতে পারবেনা আপনি এটা ব্যাবহার করেন। এবং এর মধ্যে কি
          হয়।
        </li>
        <li>আপনি খুব সহজে টাকা আপনার বিকাশ একাউন্টে পেয়ে যাবেন।</li>
      </ul>

      <br />

      <p>
        আমাদের সাথে যুক্ত হয়ে নিরাপদে আমাদের কে সহায়তা করুন এবং নিজে উপার্জন
        করুন।
      </p>

      <br />

      <p>
        আপনার যদি কোনো প্রশ্ন থাকে তাহলে সরাসরি ওয়াটস এপে যোগাযোগ করতে পারেন +44
        7915610027 এই নাম্বারে।
      </p>

      <br />
      <p>আপনার সেবায়,</p>
      <p>ইবিএম</p>
    </>
  );
};

export default AboutUsPageApp;
